<template>
  <div style="width: 100%;">
     <PdfToImg />
  </div>
</template>
 
<script>  
import PdfToImg from '@/components/PdfToImg'
export default {
  data() {
    return {
		pdfDoc: null,
		currentPage: 1,
		total: 0,
		scale: 1,
		rotate: 0, 
    }
  },
  components: {PdfToImg},
  mounted(){ 
  },
  created() { 
  	this._loadFile('https://oss.chineserd.com/group1/M00/2E/35/rBcjNmPgZOmABYPOABHhdG_XYh0192.pdf')
  },
  methods: {
	_loadFile(data) { 
	 const CMAP_URL = 'https://unpkg.zhimg.com/pdfjs-dist@2.4.456/cmaps/'; 
	 PDFJS.getDocument(data).promise.then((pdf) => {
	console.log('pdf',pdf)
	   this.pdfDoc = pdf
	   this.total = pdf.numPages
	   this.scale = 0.25
	   this.currentPage = 1
	   this.rotate = 0
	   this.$nextTick(() => {
		 this._renderPage(this.currentPage, this.scale, this.rotate);
	   });
	 })
	}, 
	_renderPage(currentPage, scale, rotate) {
		if (currentPage < 1) {
			currentPage = 1;
		}
		if (currentPage > this.total) {
			currentPage = this.total;
		}
		this.currentPage = currentPage;
		if (scale < 0.25) {
			return;
		}
		this.scale = scale;
		this.rotate = rotate % 360;
		this.pdfDoc.getPage(currentPage).then(page => {
			let viewport = page.getViewport({ scale, rotation: rotate }); 
			let canvas = this.$refs['the-canvas'];
			let context = canvas.getContext('2d');
			// this.height = viewport.height;
			// this.width = viewport.width;
	
			let renderContext = {
				canvasContext: context,
				viewport: viewport
			};
			page.render(renderContext);
		});
	},
  }
}
</script>
<style>
</style>
