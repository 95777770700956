<template>
  <div style="margin: 0;padding: 40px;display: flex;"> 
  <div @click="_renderPage(currentPage, scale - 0.25, rotate)">缩小</div>
  <div @click="_renderPage(currentPage, scale + 0.25, rotate)">放大</div>
  <div @click="canvasToImg()">图片</div>
    <div   class="canvas" :style="{height: height + 'px', width: width + 'px'}">
      <canvas ref="the-canvas" :height="height" :width="width">
      </canvas>   
    </div>
	 
	 
	<img :src="imgurl" alt=""/>
  </div>
</template>

<script>
import PDFJS from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry' 
PDFJS.workerSrc = workerSrc;

import html2canvas from "html2canvas"
export default {
  name: 'PdfToImg',
  data() {
    return {
	  imgurl:'',
      pdfDoc: '',
      currentPage: 1,
      total: 0,
      scale: 0.25,
      rotate: 0,
      height: 842,
      width: 595,

      exportImgRadio: 0,
      exportImgButtonLoading: false,
      startPage: 1,
      endPage: 1,
      fileName: null,
    };
  },
 
  mounted() { 
     this._loadFile('https://oss.chineserd.com/group1/M00/2E/35/rBcjNmPgZOmABYPOABHhdG_XYh0192.pdf') 
  },
  methods: { 
	  getPdfImage(){
	   html2canvas(this.$refs['the-canvas']).then(canvas => {
		//将对应dom组件转成图片并生成图片地址
		  let imgUrl = canvas.toDataURL() 
		  console.log(imgUrl)
		  this.imgurl = imgUrl
		}) 
	  },
    canvasToImg(currentPage, scale, rotate, resolve) { 
		console.log('page:',currentPage)
	  this.pdfDoc.getPage(currentPage).then((page) => { 
		  console.log('pages:',page)
        let viewport = page.getViewport({scale, rotation: rotate});

        let canvas = document.createElement('canvas');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        let renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };
		 
		
        page.render(renderContext).promise.then(() => {
          canvas.toBlob((imgData) => { 
            resolve(imgData)
          })
        });
		
		
      })
    },
    _renderPage(currentPage, scale, rotate) {
		console.log('scale::',scale)
		console.log('currentPagescale::',currentPage)
      if (currentPage < 1) {
        currentPage = 1
      }
      if (currentPage > this.total) {
        currentPage = this.total
      }
      this.currentPage = currentPage
      if (scale < 0.25) {
        return
      }
      this.scale = scale
      this.rotate = rotate % 360
      this.pdfDoc.getPage(currentPage).then((page) => {
		console.log('page:::',page)
        let viewport = page.getViewport({scale, rotation: rotate});
		console.log('viewport',viewport)
        let canvas = this.$refs['the-canvas'];
        let context = canvas.getContext('2d');
        this.height = viewport.height;
        this.width = viewport.width;  
        let renderContext = {
          canvasContext: context,
          viewport: viewport
        }; 
		
		page.render(renderContext); 
		// this.imgurl = canvas.toDataURL('image/jpg')
		// console.log(canvas.toDataURL('image/png'))
		
      })
    },
    _loadFile(data) {
      // https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/cmaps/
      const CMAP_URL = 'https://unpkg.zhimg.com/pdfjs-dist@2.4.456/cmaps/';
	  // {data: data, cMapUrl: CMAP_URL, cMapPacked: true}
      PDFJS.getDocument(data).promise.then((pdf) => {
		console.log('pdf',pdf)
        this.pdfDoc = pdf
        this.total = pdf.numPages
        this.scale = 0.25
        this.currentPage = 1
        this.rotate = 0
        this.$nextTick(() => {
          this._renderPage(this.currentPage, this.scale, this.rotate);
        });
      })
    }, 
  }
};


</script>

<style scoped>
.canvas {
  box-shadow: 0 0 5px 1px #999;
  position: relative;
}
</style>
